import SectionWrapper from 'components/Common/SectionWrapper';
import React from 'react';
import FormLabel from 'components/Forms/FormLabel';
import Checkbox from 'components/Forms/Checkbox';
import FormContainer from 'components/Forms/FormContainer';
import FormContent from 'components/Forms/FormContent';

interface SettingsSectionProps {
  checkboxLabels: string[];
  checkedStates: boolean[];
  onCheckboxChange: (index: number, checked: boolean) => void;
}

const SettingsSection: React.FC<SettingsSectionProps> = ({ checkboxLabels, checkedStates, onCheckboxChange }) => {
  return (
    <SectionWrapper
      title="Settings"
      description="Adjust the settings to match the output to your desired style."
    >
      <FormContainer className="gap-4">
        <FormLabel
          label="Tone of Voice"
          description="Adjust the tone of your message."
        />
        <FormContent>
          <div className="flex flex-wrap gap-4">
            {checkboxLabels.map((label, index) => (
              <Checkbox
                key={index}
                label={label}
                isChecked={checkedStates[index]}
                onChange={(checked) => onCheckboxChange(index, checked)}
              />
            ))}
          </div>
        </FormContent>
      </FormContainer>
    </SectionWrapper>
  );
};

export default SettingsSection;
