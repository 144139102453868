import InputSection from "components/Copywriting/InputSection";
import Header from "components/Common/Header";
import React, { useState, useRef } from "react";
import SettingsSection from "components/Copywriting/SettingsSection";
import Button from "components/Forms/Button";
import generateIcon from "assets/images/star-06-white.svg";
import FormContainer from "components/Forms/FormContainer";
import OutputSection from "components/Sections/OutputSection";
import { useAuth0 } from "@auth0/auth0-react";
import { DropdownItem } from "components/Forms/DropDown";
import linkedinIcon from "assets/images/linkedin-icon.svg";
import CustomizedSnackbars from "components/Forms/Snackbar";
import ProgressBar from "components/Forms/ProgressBar";
import {convertStatusMessage} from "components/Copywriting/StatusConverter";

const CopywritingPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [outputContent, setOutputContent] = useState({
    text: "",
    html: "<p>Generate content and you shall find the output here.</p>",
  });

  // State for input values
  const [inspiration, setInspiration] = useState("");
  const [enhancedSearch, setEnhancedSearch] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<string>("linkedin");
  const [selectedTemplate, setSelectedTemplate] = useState<string>("COPYWRITING_default");
  const [selectedTaskType, setSelectedTaskType] =
    useState<string>("social-media");
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info'>('error');
  const [isGenerated, setIsGenerated] = useState(false);
  const outputRef = useRef<HTMLDivElement>(null);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [postId, setPostId] = useState<string>("");

  // Polling function to check completion status
  const checkCompletion = async (backend_api_url: string, token: string, postId: string) => {
    const completion_check = await fetch(`${backend_api_url}/copywriter/posts/${postId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!completion_check.ok) {
      throw new Error("Completion check failed");
    }

    const completion_data = await completion_check.json();
    
    if (!completion_data || !completion_data.status) {
      throw new Error("Invalid response format");
    }

    const { progress, status } = convertStatusMessage(completion_data.status.status, completion_data.status.status_message);
    setProgressPercentage(progress);
    setStatusMessage(status);
    
    if (completion_data.status.status === "completed") {
      if (!completion_data.output?.history?.length) {
        throw new Error("No output content available");
      }
      
      setOutputContent({
        text: completion_data.output.history[completion_data.output.history.length - 1].text,
        html: `<p>${completion_data.output.history[completion_data.output.history.length - 1].text}</p>`,
      });
      setIsGenerated(true);
      setLoading(false);
      setStatusMessage("");
      setProgressPercentage(0);

      // Scroll to output section
      setTimeout(() => {
        outputRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      // Retry after a delay if not completed
      setTimeout(() => checkCompletion(backend_api_url, token, postId), 200);
    }
  };

  const handleGenerate = async () => {
    if (inspiration === "") {
      setErrorMessage("Please enter an inspiration");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      return;
    }
    try {
      setLoading(true);
      setStatusMessage("Initializing...");

      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;

      if (!backend_api_url) {
        console.error("Backend API URL is not defined");
        setLoading(false);
        return;
      }

      const response = await fetch(`${backend_api_url}/copywriter/posts`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          inspiration: {
            text: inspiration,
          },
          enhanced_search_enabled: enhancedSearch,
          target_platform: selectedPlatform,
          style_template_id: selectedTemplate,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "An error occurred");
      }

      const postId = await response.json();
      setPostId(postId);


      // Start polling
      checkCompletion(backend_api_url, token, postId);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while generating content");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      setStatusMessage("Error occurred");
    } 
  };

  // Handlers for input changes
  const handleInspirationChange = (value: string) => {
    setInspiration(value);
  };

  const handleEnhancedSearchToggle = (value: boolean) => {
    setEnhancedSearch(value);
  };

  const handlePlatformSelect = (item: DropdownItem) => {
    setSelectedPlatform(item.value);
  };

  const handleStyleSelect = (item: DropdownItem) => {
    setSelectedTemplate(item.value);
  };

  const handleTaskTypeSelect = (value: string) => {
    setSelectedTaskType(value);
  };

  const handleShare = () => {
    setErrorMessage("Not implemented yet");
    setSnackbarKey(prev => prev + 1);
    setSnackbarType('error');
  };

  const handleRePrompt = async (updateContent: string) => {
    if (updateContent === "") {
      setErrorMessage("Please enter a reprompt");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      return;
    }
    if (postId === "") {
      setErrorMessage("Please generate content first");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      return;
    }
    try {
      setLoading(true);
      setStatusMessage("Initializing...");

      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;

      if (!backend_api_url) {
        console.error("Backend API URL is not defined");
        setLoading(false);
        return;
      }

      const response = await fetch(`${backend_api_url}/copywriter/posts/${postId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: updateContent,
        }),
      });
      checkCompletion(backend_api_url, token, postId);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while reprompting");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      setStatusMessage("Error occurred");
    }
  };

  return (
    <>
      <Header title="Copywriting" />
      <InputSection onInspirationChange={handleInspirationChange} />
      <SettingsSection
        onTaskTypeSelect={handleTaskTypeSelect}
        onPlatformSelect={handlePlatformSelect}
        onEnhancedSearchToggle={handleEnhancedSearchToggle}
        onStyleSelect={handleStyleSelect}
        selectedPlatform={selectedPlatform}
        selectedTemplate={selectedTemplate}
      />
      <FormContainer className="mt-8 justify-center">
        
          {loading ? (
            <ProgressBar progress={progressPercentage} statusMessage={statusMessage} />
        ) : (
          <Button
            variant="primary"
            type="button"
            size="md"
            onClick={handleGenerate}
            disabled={loading}
          >
            <img src={generateIcon} alt="Generate" className="w-5 h-5" />
            Generate
          </Button>
        )}
      </FormContainer>
      {!loading && isGenerated && (
        <div ref={outputRef}>
          <OutputSection
            outputContent={outputContent}
            setOutputContent={setOutputContent}
            isGenerated={isGenerated}
            handleRePrompt={handleRePrompt}
            shareOptions={[
              {
                icon: linkedinIcon,
                label: "Share on Linkedin",
                onClick: handleShare
              }
            ]}
            title="Copywriting Output"
            description="Edit your copy before sharing it."
          />
        </div>
      )}
      {errorMessage && (
        <CustomizedSnackbars 
          message={errorMessage} 
          type={snackbarType}
          key={snackbarKey}
        />
      )}    
    </>
  );
};

export default CopywritingPage;
