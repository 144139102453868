import React, { useState } from 'react';
import SectionWrapper from 'components/Common/SectionWrapper';
import FormContainer from 'components/Forms/FormContainer';
import FormLabel from 'components/Forms/FormLabel';
import FormContent from 'components/Forms/FormContent';
import PromptEditor from 'components/Forms/PromptEditor';
import Button from 'components/Forms/Button';
import TextField from 'components/Forms/TextField';
import Spinner from "components/Forms/Spinner";
import saveIcon from "assets/images/save.svg";
import deleteIcon from "assets/images/delete.svg";
import { useAuth0 } from "@auth0/auth0-react";
import DropDown from 'components/Forms/DropDown';
import { DropdownItem } from 'components/Forms/DropDown';
import Toggle from 'components/Forms/Toggle';
import CustomizedSnackbars from 'components/Forms/Snackbar';

interface CreateSectionProps {
  dropdownItems: DropdownItem[];
  fetchDropdownItems: () => void;
  tool: string;
}

interface PromptData {
  id?: string;
  name: string;
  content: string;
}

const CreateSection: React.FC<CreateSectionProps> = ({ dropdownItems, fetchDropdownItems, tool }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [editorType, setEditorType] = useState<'create' | 'edit' | 'delete'>(
    () => (sessionStorage.getItem('promptEditorType') as 'create' | 'edit' | 'delete') || 'create'
  );
  const [createPromptData, setCreatePromptData] = useState<PromptData>(() => {
    const saved = sessionStorage.getItem('createPromptData');
    return saved ? JSON.parse(saved) : { name: '', content: '' };
  });
  const [editPromptData, setEditPromptData] = useState<PromptData>(() => {
    const saved = sessionStorage.getItem('editPromptData');
    return saved ? JSON.parse(saved) : { name: '', content: '' };
  });
  const [deletePromptData, setDeletePromptData] = useState<PromptData>(() => {
    const saved = sessionStorage.getItem('deletePromptData');
    return saved ? JSON.parse(saved) : { name: '', content: '' };
  });
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info'>('success');

  React.useEffect(() => {
    sessionStorage.setItem('promptEditorType', editorType);
    sessionStorage.setItem('createPromptData', JSON.stringify(createPromptData));
    sessionStorage.setItem('editPromptData', JSON.stringify(editPromptData));
    sessionStorage.setItem('deletePromptData', JSON.stringify(deletePromptData));
  }, [editorType, createPromptData, editPromptData, deletePromptData]);

  const clearStorageAfterOperation = () => {
    setActivePromptData({ name: '', content: '' });
    sessionStorage.removeItem(`${editorType}PromptData`);
  };

  const handleEditorTypeSelect = (value: string) => {
    const newType = value as 'create' | 'edit' | 'delete';
    setEditorType(newType);
  };

  const activePromptData = {
    create: createPromptData,
    edit: editPromptData,
    delete: deletePromptData
  }[editorType];

  const setActivePromptData = {
    create: setCreatePromptData,
    edit: setEditPromptData,
    delete: setDeletePromptData
  }[editorType];

  const handlePromptSelect = (item: DropdownItem) => {
    setActivePromptData({
      id: item.value,
      name: item.label,
      content: item.content || ''
    });
  };

  const handlePromptChange = (content: string) => {
    setActivePromptData(prev => ({ ...prev, content }));
  };

  const handlePromptNameChange = (name: string) => {
    setActivePromptData(prev => ({ ...prev, name }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
    if (!backendApiUrl) {
      console.error('Backend API URL is not defined');
      return;
    }
    const token = await getAccessTokenSilently();

    if (editorType === 'delete') {
      if (!activePromptData.id) {
        setSnackbarMessage("Please select a prompt to delete");
        setSnackbarKey(prev => prev + 1);
        setSnackbarType('error');
        setLoading(false);
        return;
      }
      const url = new URL(backendApiUrl + '/templates/delete_template');
      url.searchParams.append('template_id', activePromptData.id || '');
      const response = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Response from backend:', data);
        fetchDropdownItems();
        setSnackbarMessage("Deleted prompt");
        setSnackbarKey(prev => prev + 1);
        setSnackbarType('success');
        clearStorageAfterOperation();
        
        if (editPromptData.id === activePromptData.id) {
          setEditPromptData({ name: '', content: '' });
          sessionStorage.removeItem('editPromptData');
        }
      } else {
        console.error('Error calling backend:', response.statusText);
      }
    } else if (editorType === 'create') {
        if (activePromptData.name === '' || activePromptData.content === '') {
            setSnackbarMessage("Please enter a name and prompt");
            setSnackbarKey(prev => prev + 1);
            setSnackbarType('error');
            setLoading(false);
            return;
        }
        const url = new URL(backendApiUrl + '/templates/add_template');
        url.searchParams.append('tool', tool);
        const response = await fetch(url.toString(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            template: activePromptData.content,
            name: activePromptData.name
        })
        });

        if (response.ok) {
        const data = await response.json();
        console.log('Response from backend:', data);
        fetchDropdownItems();
        setSnackbarMessage("Saved prompt");
        setSnackbarType('success');
        setSnackbarKey(prev => prev + 1);
        clearStorageAfterOperation();
        } else {
            console.error('Error calling backend:', response.statusText);
        }
    }
    else {
        if (!activePromptData.id) {
            setSnackbarMessage("Please select a prompt to edit");
            setSnackbarKey(prev => prev + 1);
            setSnackbarType('error');
            setLoading(false);
            return;
        }
        if (activePromptData.name === '' || activePromptData.content === '') {
            setSnackbarMessage("Please enter a name and prompt");
            setSnackbarKey(prev => prev + 1);
            setSnackbarType('error');
            setLoading(false);
            return;
        }
        const url = new URL(backendApiUrl + '/templates/update_template');
        const response = await fetch(url.toString(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            template_id: activePromptData.id,
            template: activePromptData.content,
            name: activePromptData.name
        })
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Response from backend:', data);
            fetchDropdownItems();
            setSnackbarMessage("Saved changes");
            setSnackbarType('success');
            setSnackbarKey(prev => prev + 1);
            clearStorageAfterOperation();
        } else {
            console.error('Error calling backend:', response.statusText);
        }
    }
    setLoading(false);
  };

  return (
    <SectionWrapper 
      title="Create, edit, or delete prompts"
      description="Create a prompt, edit an existing one, or delete a prompt."
    >
      <FormContainer className='gap-4'>
        <FormLabel
          label="Editor type"
          description="Choose the type of editor you want to use."
        />
        <FormContent>
          <Toggle
            options={[
              { caption: 'Create', value: 'create' },
              { caption: 'Edit', value: 'edit' },
              { caption: 'Delete', value: 'delete' }
            ]}
            onSelect={handleEditorTypeSelect}
          />
        </FormContent>
      </FormContainer>

      {(editorType === 'edit' || editorType === 'delete') && (
        <FormContainer className="gap-4">
          <FormLabel
            label="Prompt"
            description={`Select the prompt you want to ${editorType}.`}
            required={true}
          />
          <FormContent>
            <DropDown
              items={dropdownItems}
              onSelect={handlePromptSelect}
              placeholder="Select a prompt"
              defaultSelected={activePromptData.id}
            />
          </FormContent>
        </FormContainer>
      )}     

      {editorType !== 'delete' && (
        <>
          <FormContainer className="gap-4">
            <FormLabel
              label="Name"
              description="Name of your prompt."
              required={true}
            />
            <FormContent>
              <TextField
                name="prompt_name"
                ariaLabel="Name of your prompt"
                placeholder="Prompt name"
                onChange={handlePromptNameChange}
                showmaxChars={false}
                initialValue={activePromptData.name}
              />
            </FormContent>
          </FormContainer>

          <FormContainer className="gap-4">
            <FormLabel
              label="Prompt"
              description="Write your prompt here."
              required={true}
            />
            <FormContent>
              {tool === 'LMG' ? (
                <PromptEditor
                  defaultPrompt={activePromptData.content}
                  onPromptChange={handlePromptChange}
                />
              ) : (
                <TextField
                  name="prompt"
                  ariaLabel="Prompt"
                  placeholder="Write your prompt here."
                  maxChars={1000}
                  onChange={handlePromptChange}
                  fixedHeight={true}
                  initialValue={activePromptData.content}
                />
              )}
            </FormContent>
          </FormContainer>
        </>
      )}
        
      <FormContainer className="gap-4">
        <FormLabel
          label={editorType === 'delete' ? 'Delete' : 'Save'} 
          description={`${editorType === 'delete' ? 'Delete' : 'Save'} your prompt here.`}
        />
        <FormContent>
          <div className="flex flex-wrap gap-8">
            <Button 
              variant="primary" 
              type="button" 
              size="md" 
              onClick={handleSubmit} 
              disabled={loading}
            >
              {loading ? (
                <Spinner />
              ) : (
                <img 
                  src={editorType === 'delete' ? deleteIcon : saveIcon} 
                  alt={editorType === 'delete' ? 'Delete' : 'Save'} 
                  className="w-5 h-5" 
                />
              )}
              {loading ? `${editorType === 'delete' ? 'Deleting...' : 'Saving...'}` : 
                        `${editorType === 'delete' ? 'Delete' : 'Save'}`} 
            </Button>
          </div>
        </FormContent>
      </FormContainer>

      {snackbarMessage && (
        <CustomizedSnackbars 
          message={snackbarMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </SectionWrapper>
  );
};

export default CreateSection;
