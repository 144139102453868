import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

interface ProgressBarProps {
  progress: number;
  statusMessage: string;
}

// Create a styled component to customize the LinearProgress
const CustomLinearProgress = styled(LinearProgress)({
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#7C3AED', // Hex code for bg-violet-500
  },
  height: 10, // Increase the height of the progress bar
});

export default function ProgressBar({ progress, statusMessage }: ProgressBarProps) {
  return (
    <div style={{ width: '50%' }}>
      <CustomLinearProgress color="primary" variant="buffer" value={progress} valueBuffer={progress} />
      <p>{statusMessage}</p>
    </div>
  );
}

