import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "components/Common/Header";
import ConnectSection from "components/LMG/ConnectSection";
import InputSection from "components/LMG/InputSection";
import SettingsSection from "components/LMG/SettingsSection";
import FormContainer from "components/Forms/FormContainer";
import Button from "components/Forms/Button";
import OutputSection from "components/Sections/OutputSection";
import Spinner from "components/Forms/Spinner";
import generateIcon from "assets/images/star-06-white.svg";
import { DropdownItem } from "components/Forms/DropDown";
import CustomizedSnackbars from "components/Forms/Snackbar";
import { useUserContext } from "contexts/UserContext";
import linkedinIcon from "assets/images/linkedin-icon.svg";

const LMGPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { userData } = useUserContext();
  const [profileUrl, setProfileUrl] = useState(() => 
    sessionStorage.getItem('lmg_profileUrl') || ""
  );
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
  const [instructions, setInstructions] = useState(() => 
    sessionStorage.getItem('lmg_instructions') || ""
  );
  const [snackbarKey, setSnackbarKey] = useState(0);
  const initialOutputContent = `
        <p>Generate a message and you shall find the output here.<p>
    `;
  const [outputContent, setOutputContent] = useState(() => {
    const saved = sessionStorage.getItem('lmg_outputContent');
    return saved ? JSON.parse(saved) : {
      text: "",
      html: initialOutputContent,
    };
  });

  const checkboxLabels = [
    "Creative",
    "Empathetic",
    "Friendly",
    "Funny",
    "Motivational",
    "Persuasive",
    "Professional",
  ];

  const [checkedStates, setCheckedStates] = useState<boolean[]>(() => {
    const saved = sessionStorage.getItem('lmg_checkedStates');
    return saved ? JSON.parse(saved) : Array(checkboxLabels.length).fill(false);
  });
  
  const [selectedPrompt, setSelectedPrompt] = useState<string>(() => 
    sessionStorage.getItem('lmg_selectedPrompt') || "default"
  );
  const [isGenerated, setIsGenerated] = useState(() => 
    sessionStorage.getItem('lmg_isGenerated') === 'true'
  );

  const [linkedinSessionCookie, setLinkedinSessionCookie] = useState(() => 
    sessionStorage.getItem('lmg_linkedinSessionCookie') || ""
  );

  const outputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isGenerated && outputRef.current) {
      outputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isGenerated]);

  // Add useEffect hooks to save state changes to sessionStorage
  useEffect(() => {
    sessionStorage.setItem('lmg_profileUrl', profileUrl);
  }, [profileUrl]);

  useEffect(() => {
    sessionStorage.setItem('lmg_instructions', instructions);
  }, [instructions]);

  useEffect(() => {
    sessionStorage.setItem('lmg_outputContent', JSON.stringify(outputContent));
  }, [outputContent]);

  useEffect(() => {
    sessionStorage.setItem('lmg_checkedStates', JSON.stringify(checkedStates));
  }, [checkedStates]);

  useEffect(() => {
    sessionStorage.setItem('lmg_selectedPrompt', selectedPrompt);
  }, [selectedPrompt]);

  useEffect(() => {
    sessionStorage.setItem('lmg_isGenerated', isGenerated.toString());
  }, [isGenerated]);

  useEffect(() => {
    sessionStorage.setItem('lmg_linkedinSessionCookie', linkedinSessionCookie);
  }, [linkedinSessionCookie]);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info'>('success');

  const handleUrlInputChange = (value: string) => {
    setProfileUrl(value);
  };

  const handleInstructionsInputChange = (value: string) => {
    setInstructions(value);
  };

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = checked;
    setCheckedStates(newCheckedStates);
  };

  const handleShare = () => {
    setErrorMessage("Not implemented yet");
    setSnackbarKey(prev => prev + 1);
    setSnackbarType('error');
  };

  const handleButtonClick = async () => {
    if (!userData) {
      setErrorMessage("Please login to generate a message.");
      setSnackbarKey(prev => prev + 1);
      setSnackbarType('error');
      return;
    }
    if (!userData.first_name || !userData.last_name) {
      setErrorMessage("Set up your profile in the Settings tab to generate a message.");
      setSnackbarKey(prev => prev + 1);
      setSnackbarType('error');
      return;
    }

    // Validate the profileUrl
    try {
      new URL(profileUrl); // This will throw an error if the URL is invalid
    } catch {
      setErrorMessage("Please enter a valid URL.");
      setSnackbarKey(prev => prev + 1);
      setSnackbarType('error');
      return; // Exit the function if the URL is invalid
    }

    setErrorMessage(null); // Clear any previous error messages

    try {
      const checkedLabels = checkboxLabels
        .filter((_, index) => checkedStates[index])
        .join(", ");
      const backend_instructions =
        instructions +
        " Additionally use the following tone of voice: " +
        checkedLabels;

      setLoading(true);

      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
      if (!backend_api_url) {
        console.error("Backend API URL is not defined");
        setLoading(false);
        return;
      }

      const response = await fetch(`${backend_api_url}/lmg/generate`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          generate: true,
          url: profileUrl,
          instructions: backend_instructions,
          template_id: selectedPrompt || "default",
          update: "",
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "An error occurred");
      }

      const data = await response.json();
      setOutputContent({ text: data, html: `<p>${data}</p>` });
      setIsGenerated(true);
    } catch (error) {
      console.error("Error:", error);
      // Here you could also set an error state to display to the user
      setErrorMessage("An error occurred");
      setSnackbarKey(prev => prev + 1);
      setSnackbarType('error');
    } finally {
      setLoading(false);
    }
  };

  const handlePromptSelect = (uuid: string) => {
    console.log("Selected prompt UUID from InputSection:", uuid);
    setSelectedPrompt(uuid);
  };

  const setDefaultPromptIfExists = (items: DropdownItem[]) => {
    const defaultPrompt = items.find(item => item.value === "LMG_default");
    if (defaultPrompt) {
      setSelectedPrompt(defaultPrompt.value);
    }
  };

  const fetchDropdownItems = async () => {
    try {
      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
      if (!backend_api_url) {
        console.error('Backend API URL is not defined');
        return;
      }

      const url = new URL(backend_api_url + '/templates/get_tool_templates');
      url.searchParams.set('tool', 'LMG');

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log(data);
      const items = data.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setDropdownItems(items);
      setDefaultPromptIfExists(items);
    } catch (error) {
      console.error('Fehler beim Abrufen der Dropdown-Elemente:', error);
    }
  };
  const handleRePrompt = async (updateContent: string) => {
    const token = await getAccessTokenSilently();
    const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
    if (!backend_api_url) {
      console.error("Backend API URL is not defined");
      return;
    }

    const response = await fetch(`${backend_api_url}/lmg/generate`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        generate: false,
        url: "", // Not needed for updates
        template_id: "default",
        instructions: "", // Not needed for updates
        update: updateContent,
      }),
    });

    const data = await response.json();
    setOutputContent({ text: data, html: `<p>${data}<p>` });
  };

  const handleSessionCookieChange = (value: string) => {
    setLinkedinSessionCookie(value);
  };

  return (
    <>
      <Header title="LinkedIn Message Generator" />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <ConnectSection 
        sessionCookie={linkedinSessionCookie}
        onSessionCookieChange={handleSessionCookieChange}
      />
      <InputSection
        onUrlInputChange={handleUrlInputChange}
        onInstructionsInputChange={handleInstructionsInputChange}
        onPromptSelect={handlePromptSelect}
        dropdownItems={dropdownItems}
        fetchDropdownItems={fetchDropdownItems}
        selectedPrompt={selectedPrompt}
      />
      <SettingsSection
        checkboxLabels={checkboxLabels}
        checkedStates={checkedStates}
        onCheckboxChange={handleCheckboxChange}
      />
      <FormContainer className="mt-8 justify-center">
        <Button
          variant="primary"
          type="button"
          size="md"
          onClick={handleButtonClick}
          disabled={loading}
        >
          {loading ? (
            <Spinner />
          ) : (
            <img src={generateIcon} alt="Generate" className="w-5 h-5" />
          )}
          {loading ? "Generating..." : "Generate"}
        </Button>
      </FormContainer>
      {isGenerated && (
        <div ref={outputRef}>
          <OutputSection
            outputContent={outputContent}
            setOutputContent={setOutputContent}
            isGenerated={isGenerated}
            handleRePrompt={handleRePrompt}
            shareOptions={[
              {
                icon: linkedinIcon,
                label: "Message on LinkedIn",
                onClick: handleShare
              }
            ]}
            title="LinkedIn Message"
            description="Edit your LinkedIn message before posting it."
          />
        </div>
      )}
      {errorMessage && (
        <CustomizedSnackbars 
          message={errorMessage} 
          type={snackbarType}
          key={snackbarKey}
        />
      )}    
    </>
  );
};

export default LMGPage;
