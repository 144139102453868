import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Layout from "components/Layout/Layout";
import LoginPage from "pages/LoginPage";
import HomePage from "pages/HomePage";
import CopywritingPage from "pages/CopywritingPage";
import ResearchPage from "pages/ResearchPage";
import ResearchResultsPage from "pages/ResearchResultsPage";
import DesignPage from "pages/DesignPage";
import LMGPage from "pages/LMGPage";
import SettingsPage from "pages/SettingsPage";
import SupportPage from "pages/SupportPage";
import PromptPage from "pages/PromptPage";
import { useUserContext } from "contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";

import logo from "assets/images/logo.svg";
import userAvatar from "assets/images/userAvatar.png";
import homeIcon from "assets/images/home-line.svg";
import createIcon from "assets/images/edit-04.svg";
import researchIcon from "assets/images/search-refraction.svg";
import designIcon from "assets/images/palette.svg";

function AppContent() {
  const navigate = useNavigate();
  const { userData } = useUserContext();
  const { logout } = useAuth0();

  const sidebarProps = {
    logo: logo,
    navItems: [
      { icon: homeIcon, label: "Home", path: "/home" },
      { icon: createIcon, label: "Copywriting", path: "/copywriting" },
      { icon: researchIcon, label: "Research", path: "/research" },
      { icon: designIcon, label: "Design", path: "/design" },
      { icon: createIcon, label: "LnkdnWriter", path: "/lmg" },
    ],
    userProfile: {
      avatar: userAvatar,
      name: `${userData?.first_name || "Example"} ${userData?.last_name || "User"}`,
      email: userData?.email || "example@user.com",
      onLogout: () => {
        console.log("Logout clicked");
        logout({
          logoutParams: { returnTo: window.location.origin },
        });
        navigate("/login");
      },
    },
    onSearchInputChange: (value: string) => {
      console.log("Search input changed:", value);
    },
    onSearchSubmit: (value: string) => {
      console.log("Search submitted:", value);
    },
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route element={<Layout sidebarProps={sidebarProps} />}>
        <Route path="/home" element={<HomePage />} />
        <Route path="/copywriting" element={<CopywritingPage />} />
        <Route path="/research" element={<ResearchPage />} />
        <Route path="/research/results" element={<ResearchResultsPage />} />
        <Route path="/design" element={<DesignPage />} />
        <Route path="/lmg" element={<LMGPage />} />
        <Route path="/prompt/lmg" element={<PromptPage tool="LMG" />} />
        <Route path="/prompt/copywriting" element={<PromptPage tool="COPYWRITING" />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/support" element={<SupportPage />} />
      </Route>
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
