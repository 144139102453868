import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SectionWrapper from "components/Common/SectionWrapper";
import FormContainer from "components/Forms/FormContainer";
import FormContent from "components/Forms/FormContent";
import FormLabel from "components/Forms/FormLabel";
import TextField from "components/Forms/TextField";
import Button from "components/Forms/Button";
import DraftEditor from "components/Forms/DraftEditor";
import generateIcon from "assets/images/star-06-white.svg";
import copyIcon from "assets/images/copy-05-white.svg";
import Spinner from "components/Forms/Spinner";
import CustomizedSnackbars from "components/Forms/Snackbar";
interface OutputSectionProps {
  outputContent: {
    text: string;
    html: string;
  };
  setOutputContent: (content: { text: string; html: string }) => void;
  handleRePrompt: (value: string) => void;
  isGenerated: boolean;
  shareOptions?: {
    icon: string;
    label: string;
    onClick: () => void;
  }[];
  title?: string;
  description?: string;
}

const OutputSection: React.FC<OutputSectionProps> = ({
  outputContent,
  setOutputContent,
  handleRePrompt,
  isGenerated,
  shareOptions = [],
  title = "Output",
  description = "Here you can control the output generation."
}) => {
  const [updateContent, setUpdateContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'warning' | 'info'>('success');

  const handleRePromptInputChange = (value: string) => {
    setUpdateContent(value);
  };

  const handleRegenerateOutput = async () => {
    setLoading(true);
    if (!isGenerated) {
      setErrorMessage("Please generate an output first");
      setSnackbarKey(prev => prev + 1);
      setSnackbarType('error');
      setLoading(false);
      return;
    }

    try {
      await handleRePrompt(updateContent);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToClipboard = () => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = outputContent.html; // Setze den HTML-Inhalt
    document.body.appendChild(tempDiv); // Füge das Element zum DOM hinzu

    const range = document.createRange();
    range.selectNodeContents(tempDiv); // Wähle den Inhalt des divs aus
    const selection = window.getSelection();
    if (selection) {
        selection.removeAllRanges(); // Entferne vorherige Auswahl
        selection.addRange(range); // Füge die neue Auswahl hinzu
    }

    if (selection) {
        try {
            document.execCommand("copy"); // Kopiere die Auswahl in die Zwischenablage
            console.log("Inhalt in die Zwischenablage kopiert");
        } catch (err) {
            console.error("Fehler beim Kopieren des Inhalts: ", err);
        }
    }

    document.body.removeChild(tempDiv); // Entferne das temporäre Element
  };

  const handleEditorContentChange = (content: {
    text: string;
    html: string;
  }) => {
    setOutputContent(content);
    console.log("Editor content changed:", content);
  };

  return (
    <SectionWrapper
      title={title}
      description={description}
    >
      <FormContainer className="gap-4">
        <FormLabel
          label="Output"
          description="Edit your output before posting it."
        />
        <FormContent>
          <DraftEditor
            initialValue={outputContent.html}
            onContentChange={handleEditorContentChange}
            maxChars={5000}
          />
        </FormContent>
      </FormContainer>
      <FormContainer className="gap-4">
        <FormLabel label="Re-Prompt" description="Do you need adjustments?" />
        <FormContent>
          <div className="flex gap-2">
            <div className="flex-grow">
              <TextField
                name={"reprompt"}
                ariaLabel={"reprompt-input"}
                placeholder={"What would you like to change?"}
                maxChars={275}
                onChange={handleRePromptInputChange}
                fixedHeight={false}
              />
            </div>
            <div className="flex-shrink-0">
              <Button
                type="button"
                variant="primary"
                size="square"
                onClick={handleRegenerateOutput}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <img src={generateIcon} alt="Generate" className="w-5 h-5" />
                )}
              </Button>
            </div>
          </div>
        </FormContent>
      </FormContainer>
      <FormContainer className="gap-4">
        <FormLabel label="Share" />
        <FormContent>
          <div className="flex flex-wrap gap-8">
            <Button
              type="button"
              variant="primary"
              size="md"
              onClick={handleCopyToClipboard}
            >
              <img src={copyIcon} alt="Copy to clipboard" className="w-5 h-5" />
              Copy to clipboard
            </Button>
            {shareOptions.map((option, index) => (
              <Button
                key={index}
                type="button"
                variant="primary"
                size="md"
                onClick={option.onClick}
              >
                <img
                  src={option.icon}
                  alt={option.label}
                  className="w-5 h-5"
                />
                {option.label}
              </Button>
            ))}
          </div>
        </FormContent>
      </FormContainer>
      {errorMessage && (
        <CustomizedSnackbars 
          message={errorMessage} 
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </SectionWrapper>
  );
};

export default OutputSection;
