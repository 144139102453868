import React from 'react';
import { useNavigate } from 'react-router-dom';

interface HeaderWithBackProps {
  title: string;
}

const HeaderWithBack: React.FC<HeaderWithBackProps> = ({ title }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex items-center">
      <button onClick={handleGoBack} className="mr-4 text-3xl">
        ←
      </button>
      <h1 className="text-3xl font-semibold leading-none">{title}</h1>
    </div>
  );
};

export default HeaderWithBack;