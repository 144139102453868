import React, { useState, useEffect } from 'react';
import HeaderWithBack from 'components/Common/HeaderWithBack';
import CreateSection from 'components/Prompt/CreateSection';
import { useAuth0 } from '@auth0/auth0-react';
import { DropdownItem } from 'components/Forms/DropDown';

interface PromptPageProps {
  tool: string;
}


const PromptPage: React.FC<PromptPageProps> = ({ tool }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);

  const fetchDropdownItems = async () => {
    try {
      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
      if (!backend_api_url) {
        console.error('Backend API URL is not defined');
        return;
      }

      const url = new URL(backend_api_url + '/templates/get_user_templates');
      url.searchParams.append('tool', tool);

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log(data);
      const items = data.map((item: any) => ({
        label: item.name,
        value: item.id,
        content: item.prompt,
      }));
      setDropdownItems(items);
    } catch (error) {
      console.error('Fehler beim Abrufen der Dropdown-Elemente:', error);
    }
  };

  useEffect(() => {
    fetchDropdownItems();
  }, []);

  return (
    <>
      <HeaderWithBack title="Prompt Editor" />
      <CreateSection dropdownItems={dropdownItems} fetchDropdownItems={fetchDropdownItems} tool={tool}/>
    </>
  );
};

export default PromptPage;