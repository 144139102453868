import * as React from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';


function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }

interface CustomizedSnackbarsProps {
  message: string;
  type?: 'success' | 'error' | 'warning' | 'info';
}

export default function CustomizedSnackbars({ 
  message, 
  type = 'success'
}: CustomizedSnackbarsProps) {
    const [open, setOpen] = React.useState(true);

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

    return (
        <div>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}
                    TransitionComponent = {SlideTransition}>
            <Alert
            onClose={handleClose}
            severity={type}
            variant="filled"
            sx={{ width: '100%' }}
            >
            {message}
            </Alert>
        </Snackbar>
        </div>
    );
}
