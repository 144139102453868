import SectionWrapper from "components/Common/SectionWrapper";
import React, { useEffect, useState } from "react";
import Toggle from "components/Forms/Toggle";
import FormLabel from "components/Forms/FormLabel";
import DropDown, { DropdownItem } from "components/Forms/DropDown";

import linkedinIcon from "assets/images/linkedin-icon.svg";
import instagramIcon from "assets/images/instagram-icon.svg";
import tinderIcon from "assets/images/tinder-icon.svg";
import ToggleSwitch from "components/Forms/ToggleSwitch";
import FormContainer from "components/Forms/FormContainer";
import FormContent from "components/Forms/FormContent";
import Button from "components/Forms/Button";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface SettingsSectionProps {
  onTaskTypeSelect: (value: string) => void;
  onPlatformSelect: (item: DropdownItem) => void;
  onEnhancedSearchToggle: (value: boolean) => void;
  onStyleSelect: (item: DropdownItem) => void;
  selectedPlatform?: string;
  selectedTemplate?: string;
}

const SettingsSection: React.FC<SettingsSectionProps> = ({
  onTaskTypeSelect,
  onPlatformSelect,
  onEnhancedSearchToggle,
  onStyleSelect,
  selectedPlatform = "linkedin",
  selectedTemplate = "default",
  }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);

  const fetchDropdownItems = async () => {
    try {
      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
      if (!backend_api_url) {
        console.error('Backend API URL is not defined');
        return;
      }

      const url = new URL(backend_api_url + '/templates/get_tool_templates');
      url.searchParams.append('tool', 'COPYWRITING');

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log(data);
      const items = data.map((item: any) => ({
        label: item.name,
        value: item.id,
        content: item.prompt,
      }));
      setDropdownItems(items);
    } catch (error) {
      console.error('Fehler beim Abrufen der Dropdown-Elemente:', error);
    }
  };

  useEffect(() => {
    fetchDropdownItems();
  }, []);
  return (
    <SectionWrapper
      title="Settings"
      description="Adjust the settings to match the output to your desired style"
    >
      {/* Tabs */}
      {/* <FormContainer className="justify-center">
        <Toggle
          options={[
            { caption: "Social Media", value: "social-media" },
            { caption: "Copy Writing", value: "copy-writing" },
            { caption: "Audio Gen", value: "audio-gen" },
            { caption: "Video Gen", value: "video-gen" },
            { caption: "Blog Post", value: "blog-post" },
          ]}
          onSelect={onTaskTypeSelect}
        />
      </FormContainer> */}

      {/* Platform */}
      <FormContainer className="gap-4">
        <FormLabel
          label="Target Platform"
          description="Select your desired platform"
        />
        <FormContent>
          <DropDown
            items={[
              { label: "LinkedIn", value: "linkedin", iconSrc: linkedinIcon },
              {
                label: "Instagram",
                value: "instagram",
                iconSrc: instagramIcon,
              },
              { label: "Tinder", value: "tinder", iconSrc: tinderIcon },
            ]}
            onSelect={onPlatformSelect}
            defaultSelected={selectedPlatform}
          />
        </FormContent>
      </FormContainer>

      {/* Custom Style */}
      <FormContainer className="gap-4">
        <FormLabel
          label="Custom Style Gudie"
          description="Select from your own templates"
        />
         <FormContent>
          <div className="flex items-center">
          <DropDown
            items={dropdownItems}
            onSelect={onStyleSelect}
            defaultSelected={selectedTemplate}
          />
            <Button
              variant="primary"
              type="button"
              size="square"
              onClick={() => navigate('/prompt/copywriting')}
              className="ml-2 h-10"
            >
              +
            </Button>
          </div>
        </FormContent>
      </FormContainer>

      {/* Enhanced Search */}
      {/* <FormContainer className="gap-4">
        <FormLabel
          label="Enhanced Search"
          description="This will perform a web search to get additional information "
        />
        <div className="flex flex-col flex-1 shrink max-w-lg basis-0 min-w-[320px] max-md:max-w-full">
          <div className="flex flex-col w-full max-md:max-w-full">
            <ToggleSwitch
              label="Enhanced search"
              id="enhancedSearch"
              onToggle={onEnhancedSearchToggle}
            />
          </div>
        </div>
      </FormContainer> */}
    </SectionWrapper>
  );
};

export default SettingsSection;
