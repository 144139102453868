import React from "react";

interface CheckboxProps {
  label: string;
  isChecked: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, isChecked, onChange }) => {
  const handleCheckboxChange = () => {
    onChange(!isChecked);
  };

  return (
    <div className="inline-flex items-start gap-[var(--3-spacing-spacing-md)] relative">
      <div className="inline-flex items-center justify-center pt-[var(--3-spacing-spacing-xxs)] pb-0 px-0 relative flex-[0_0_auto]">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="relative w-4 h-4 accent-violet-500"
        />
      </div>

      <div className="inline-flex flex-col items-start relative flex-[0_0_auto]">
        <div className="relative self-stretch mt-[-1.00px] font-text-sm-medium font-[number:var(--text-sm-medium-font-weight)] text-1-color-modes-colors-text-text-secondary-700 text-[length:var(--text-sm-medium-font-size)] tracking-[var(--text-sm-medium-letter-spacing)] leading-[var(--text-sm-medium-line-height)] [font-style:var(--text-sm-medium-font-style)]">
          {label}
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
