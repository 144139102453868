import React, { useState, useRef, useEffect } from 'react';

const parameters = [
  { id: 'user_first_name', label: 'Your first name' },
  { id: 'user_last_name', label: 'Your last name' },
  { id: 'customer_first_name', label: 'First name' },
  { id: 'customer_last_name', label: 'Last name' },
  { id: 'customer_summary', label: 'Profile summary' },
  { id: 'company_name', label: 'Company' },
  { id: 'company_description', label: 'Company description' },
];

interface PromptEditorProps {
  defaultPrompt?: string;
  onPromptChange: (newPrompt: string) => void;
}

const PromptEditor: React.FC<PromptEditorProps> = ({ onPromptChange, defaultPrompt = '' }) => {
  const [prompt, setPrompt] = useState<string>(defaultPrompt);
  const [draggedParam, setDraggedParam] = useState<string | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setPrompt(defaultPrompt);
  }, [defaultPrompt]);


  const handleDrop = (e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    if (draggedParam && textareaRef.current) {
      const cursorPosition = textareaRef.current.selectionStart;
      const textBeforeCursor = prompt.substring(0, cursorPosition);
      const textAfterCursor = prompt.substring(cursorPosition);
      const newPrompt = `${textBeforeCursor}{${draggedParam}}${textAfterCursor}`;
      setPrompt(newPrompt);
      handleChange(newPrompt);
      setDraggedParam(null);
      
      const newPosition = cursorPosition + draggedParam.length + 2;
      textareaRef.current.focus();
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.selectionStart = newPosition;
          textareaRef.current.selectionEnd = newPosition;
        }
      }, 0);
    }
  };

  const handleDragStart = (param: string) => {
    setDraggedParam(param);
  };

  const handleChange = (newPrompt: string) => {
    setPrompt(newPrompt);
    onPromptChange(newPrompt);
  };

  const handleParamClick = (param: string) => {
    if (textareaRef.current) {
      const cursorPosition = textareaRef.current.selectionStart;
      const textBeforeCursor = prompt.substring(0, cursorPosition);
      const textAfterCursor = prompt.substring(cursorPosition);
      const newPrompt = `${textBeforeCursor}{${param}}${textAfterCursor}`;
      setPrompt(newPrompt);
      handleChange(newPrompt);
      
      const newPosition = cursorPosition + param.length + 2;
      textareaRef.current.focus();
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.selectionStart = newPosition;
          textareaRef.current.selectionEnd = newPosition;
        }
      }, 0);
    }
  };

  return (
    <div className="flex flex-col p-0">
      <textarea
        ref={textareaRef}
        value={prompt}
        onChange={(e) => handleChange(e.target.value)}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        className="border border-gray-300 rounded p-4 h-48 w-full"
        style={{
          boxSizing: 'border-box',
          width: '100%',
          padding: '12px 14px',
          backgroundColor: 'white',
          borderRadius: '8px',
          border: '1px solid #d1d5db',
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#111827',
          resize: 'vertical',
          minHeight: '48px',
          maxHeight: '800px',
          overflow: 'auto'
        }}
        placeholder="Type your prompt here..."
      />
      <div className="flex gap-2 mb-4 mt-4 flex-wrap">
        {parameters.map((param) => (
          <div
            key={param.id}
            draggable
            onDragStart={() => handleDragStart(param.id)}
            onClick={() => handleParamClick(param.id)}
            className="p-2 border border-gray-300 rounded cursor-pointer text-xs"
          >
            {param.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PromptEditor;