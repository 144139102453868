import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionWrapper from 'components/Common/SectionWrapper';
import FormContainer from 'components/Forms/FormContainer';
import FormLabel from 'components/Forms/FormLabel';
import FormContent from 'components/Forms/FormContent';
import TextField from 'components/Forms/TextField';
import DropDown, { DropdownItem } from 'components/Forms/DropDown';
import Button from 'components/Forms/Button';

interface InputSectionProps {
  onUrlInputChange: (value: string) => void;
  onInstructionsInputChange: (value: string) => void;
  onPromptSelect: (uuid: string) => void;
  dropdownItems: DropdownItem[];
  fetchDropdownItems: () => void;
  selectedPrompt: string;
}

const InputSection: React.FC<InputSectionProps> = ({
  onUrlInputChange,
  onInstructionsInputChange,
  onPromptSelect,
  dropdownItems,
  fetchDropdownItems,
  selectedPrompt,
}) => {
  const navigate = useNavigate();

  const [urlValue, setUrlValue] = useState(
    sessionStorage.getItem('lmg_profileUrl') || ''
  );
  const [instructionsValue, setInstructionsValue] = useState(
    sessionStorage.getItem('lmg_instructions') || ''
  );

  const handleUrlInputChange = (text: string) => {
    setUrlValue(text);
    onUrlInputChange(text);
  };

  const handleInstructionsInputChange = (text: string) => {
    setInstructionsValue(text);
    onInstructionsInputChange(text);
  };

  const handlePromptSelect = (item: DropdownItem) => {
    console.log('Selected prompt UUID:', item.value);
    onPromptSelect(item.value);
  };

  useEffect(() => {
    fetchDropdownItems();
  }, []);

  return (
    <SectionWrapper
      title="Input"
      description="Create an opening message to a new LinkedIn profile."
    >
      <FormContainer className="gap-4">
        <FormLabel
          label="Url"
          description="Input profile you want to message."
          required={true}
        />
        <FormContent>
          <TextField
            name="profileUrl"
            ariaLabel="LinkedIn Profile URL"
            placeholder="https://www.linkedin.com/in/username"
            onChange={handleUrlInputChange}
            showmaxChars={false}
            initialValue={urlValue}
          />
        </FormContent>
      </FormContainer>
      <FormContainer className="gap-4">
        <FormLabel
          label="Prompt"
          description="Select a prompt."
          required={true}
        />
        <FormContent>
          <div className="flex items-center">
            <DropDown
              items={dropdownItems}
              onSelect={handlePromptSelect}
              placeholder="Select a prompt"
              defaultSelected={selectedPrompt}
            />
            <Button
              variant="primary"
              type="button"
              size="square"
              onClick={() => navigate('/prompt/lmg')}
              className="ml-2 h-10"
            >
              +
            </Button>
          </div>
        </FormContent>
      </FormContainer>
      <FormContainer className="gap-4">
        <FormLabel
          label="Instructions"
          description="Add any instructions you want to give."
        />
        <FormContent>
          <TextField
            name="instructions"
            ariaLabel="Instructions"
            placeholder="Add any instructions here."
            onChange={handleInstructionsInputChange}
            fixedHeight={true}
            initialValue={instructionsValue}
          />
        </FormContent>
      </FormContainer>
    </SectionWrapper>
  );
};

export default InputSection;
