import React, { useState } from 'react';
import SectionWrapper from 'components/Common/SectionWrapper';
import FormContainer from 'components/Forms/FormContainer';
import FormLabel from 'components/Forms/FormLabel';
import FormContent from 'components/Forms/FormContent';
import TextField from 'components/Forms/TextField';

interface ConnectSectionProps {
  sessionCookie: string;
  onSessionCookieChange: (value: string) => void;
}

const ConnectSection: React.FC<ConnectSectionProps> = ({
  sessionCookie,
  onSessionCookieChange
}) => {
  const [cookieValue, setCookieValue] = useState(sessionCookie);

  const handleLinkedInSessionCookieChange = (value: string) => {
    setCookieValue(value);
    onSessionCookieChange(value);
  };

  return (
    <SectionWrapper
      title="Connect"
      description="Connect your LinkedIn account."
    >
      <FormContainer className="gap-4">
        <FormLabel
          label="LinkedIn Session Cookie"
          description="Input your LinkedIn session cookie"
          required={false}
        />
        <FormContent>
          <TextField
            name="linkedin_session_cookie"
            ariaLabel="LinkedIn Session Cookie"
            placeholder="Enter your LinkedIn session cookie here..."
            onChange={handleLinkedInSessionCookieChange}
            initialValue={cookieValue}
            showmaxChars={false}
          />
        </FormContent>
      </FormContainer>
    </SectionWrapper>
  );
};

export default ConnectSection;
